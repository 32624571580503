<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            label="공정"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 재해종류 -->
          <c-multi-select
            codeGroupCd="IIM_NEAR_KIND_CD"
            itemText="codeName"
            itemValue="code"
            name="iimNearKindCds"
            label="재해종류"
            v-model="searchParam.iimNearKindCds"
          ></c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행상태 -->
          <c-select
            type="search"
            stepperGrpCd="IIM_STATUS_NEAR_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            :rejectItems="['ISNC000003']"
            name="iimStatusNearCd"
            label="진행상태"
            v-model="searchParam.iimStatusNearCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고기간 -->
          <c-datepicker
            :range="true"
            name="period"
            defaultStart="-6M"
            defaultEnd="6M"
            label="사고기간"
            v-model="searchParam.period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field
            label="재해자"
            name="accidentUserId"
            v-model="searchParam.accidentUserId">
          </c-field>
        </div>
      </template>
    </c-search-box>
    <c-table
      title="아차사고 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn v-if="editable" label="등록" icon="add" @btnClicked="nearAccidentRegister" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>  
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "nearAccident",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "사업장",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearAccidentNo",
            field: "iimNearAccidentNo",
            label: "아차사고번호",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearAccidentName",
            field: "iimNearAccidentName",
            label: "아차사고명",
            style: 'width:200px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "processName",
            field: "processName",
            label: "공정",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "iimStatusNearName",
            field: "iimStatusNearName",
            style: 'width:100px',
            label: "진행상태",
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearKindName",
            field: "iimNearKindName",
            style: 'width:120px',
            label: "재해종류",
            align: "left",
            sortable: true,
          },
          {
            name: "occurrenceDt",
            field: "occurrenceDt",
            style: 'width:120px',
            label: "사고일시",
            align: "center",
            sortable: true,
          },
          {
            name: "accidentUser",
            field: "accidentUser",
            label: '재해자 정보',
            child: [
              {
                name: "accidentDeptName",
                field: "accidentDeptName",
                style: 'width:80px',
                label: "부서",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentUserName",
                field: "accidentUserName",
                style: 'width:80px',
                label: "이름",
                align: "center",
                sortable: true,
              },
              // {
              //   name: "accidentEmpNo",
              //   field: "accidentEmpNo",
              //   style: 'width:80px',
              //   label: "사번",
              //   align: "center",
              //   sortable: true,
              // },
              {
                name: "accidentAge",
                field: "accidentAge",
                style: 'width:80px',
                label: "연령",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentLongevityCount",
                field: "accidentLongevityCount",
                style: 'width:80px',
                label: "근속년수",
                align: "center",
                sortable: true,
              },
            ]
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        accidentUserId: '',
        startYmd: '',
        endYmd: '',
        iimNearKindCds: [],
        iimStatusNearCd: null,
        processCd: '',
        period: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.iim.accident.near.list.url;
      // code setting
      // list setting 
      this.getList();
    },
    getList() {
      if (this.searchParam.period) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    nearAccidentRegister() {
      this.linkClick();
    },
    linkClick(row, col, index) {
      this.popupOptions.title = "아차사고 상세"; // 아차사고 상세
      this.popupOptions.param = {
        iimNearAccidentId: row ? row.iimNearAccidentId : '',
        iimStatusNearCd: row ? row.iimStatusNearCd : '', //아차사고 진행상태
      };
      this.popupOptions.target = () => import(`${"./nearAccidentDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    }, 
  },
};
</script>